// frontend/src/services/apiService.ts
import api, { CommonResponse } from './api'

// ANCHOR 지갑존재조회
type ExistWalletParams = {
  walletAddress: string
}
export const existWallet = async (params: ExistWalletParams) => {
  try {
    const response = await api.get<CommonResponse>('/api/main/existWallet', {
      params
    })
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 회원가입
type SignUpParams = {
  walletAddress: string | null
  username: string
  password: string
  referralCode: string
  sponsorCode: string
}

export const signUp = async (params: SignUpParams) => {
  try {
    const response = await api.post<CommonResponse>('/api/main/signUp', params)
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 로그인
type SignInParams = {
  walletAddress: string | null
  password: string
}
export const signIn = async (params: SignInParams) => {
  try {
    const response = await api.post<CommonResponse>('/api/main/signIn', params)
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 세션기반사용자조회
export const getUser = async () => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getUser')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 로그아웃
export const signOut = async () => {
  try {
    const response = await api.delete<CommonResponse>('/api/main/signOut')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 입금
type DepositParams = {
  txId: string
}
export const deposit = async (params: DepositParams) => {
  try {
    const response = await api.post<CommonResponse>('/api/main/deposit', params)
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 출금
type WithdrawalParams = {
  tokenAmount: string
}
export const withdrawal = async (params: WithdrawalParams) => {
  try {
    const response = await api.post<CommonResponse>(
      '/api/main/withdrawal',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 자동스테이킹토글
type ToggleAutoStakingParams = {
  toggle: boolean
}
export const toggleAutoStaking = async (params: ToggleAutoStakingParams) => {
  try {
    const response = await api.put<CommonResponse>(
      '/api/main/toggleAutoStaking',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 조직도조회(추천)
export const getTeamChartRef = async () => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getTeamChartRef')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 조직도조회(후원)
export const getTeamChartSup = async () => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getTeamChartSup')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 이력조회
type GetHistoryListParams = {
  page: number
  type?: string // 선택적 필터 추가
}

export const getHistoryList = async (params: GetHistoryListParams) => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getHistoryList', {
      params
    })
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 비밀번호변경
type ChangePasswordParams = {
  oldPassword: string
  newPassword: string
}
export const changePassword = async (params: ChangePasswordParams) => {
  try {
    const response = await api.put<CommonResponse>(
      '/api/main/changePassword',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 사용자명변경
type ChangeUsernameParams = {
  username: string
}
export const changeUsername = async (params: ChangeUsernameParams) => {
  try {
    const response = await api.put<CommonResponse>(
      '/api/main/changeUsername',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 시세조회
export const getWVPUSDT = async () => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getWVPUSDT')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 수동참여
export const manualJoin = async () => {
  try {
    const response = await api.post<CommonResponse>('/api/main/manualJoin')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 공지사항리스트조회
interface GetNoticeListParams {
  page: number
  offset?: number // 선택적 파라미터
  isPopup?: number // 선택적 파라미터
}
export const getNoticeList = async (params: GetNoticeListParams) => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getNoticeList', {
      params
    })
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 공지사항조회
interface GetNoticeParams {
  id: number
}
export const getNotice = async (params: GetNoticeParams) => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getNotice', {
      params
    })
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}
