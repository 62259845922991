import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Card,
  CardBody,
  HStack,
  Select,
  SimpleGrid,
  Text,
  VStack,
  Link,
  Button,
  Tag,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { getHistoryList } from '../services/apiService'
import BigNumber from 'bignumber.js'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

interface HistoryItem {
  id: number
  type: string
  userId: number
  tokenAmount: string
  beforeTokenBalance: string
  afterTokenBalance: string
  stakingAmount: string
  beforeStakingBalance: string
  afterStakingBalance: string
  fee: string | null
  txid: string | null
  byUserId: number | null
  byHistoryId: number | null
  toHistoryId: number
  toHistorySubId: number | null
  isGive: number
  rate: string
  usdtAmount: string
  note: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

const History = () => {
  const { t } = useTranslation()
  const [selectedType, setSelectedType] = useState('TOTAL')
  const [displayedItems, setDisplayedItems] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [loading, setLoading] = useState(false)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const cancelRef = useRef<any>()

  const fetchHistoryList = async (page: number, reset = false) => {
    setLoading(true)
    try {
      const { data: res } = await getHistoryList({ page, type: selectedType })
      if (res.statusCode === 200) {
        const data = res.data.data
        if (reset) {
          setDisplayedItems(data)
        } else {
          setDisplayedItems((prevItems) => [...prevItems, ...data])
        }

        if (data.length === 0 || data.length < 5) {
          setHasNextPage(false)
        } else {
          setHasNextPage(true)
        }
      }
    } catch (error: any) {
      console.error('Error fetching history:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPage(1)
    setDisplayedItems([]) // 기존 데이터 초기화
    fetchHistoryList(1, true) // 필터 변경 시 데이터를 초기화하여 로드
  }, [selectedType])

  useEffect(() => {
    if (page === 1) return // 페이지 1일 때는 이미 데이터를 불러오기 때문에 무시
    fetchHistoryList(page)
  }, [page])

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1)
  }

  const handleFilterChange = (value: string) => {
    setSelectedType(value)
    setIsDialogOpen(false) // Close the dialog after selection
  }

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  return (
    <DefaultLayout>
      <VStack spacing={4} width="full" mb={8}>
        <Button
          onClick={openDialog}
          width="full"
          bg="#33D1C9"
          color="#ffffff"
          size="lg"
        >
          {t('Select Filter')}
        </Button>

        <SimpleGrid columns={[1, 2, 3]} spacing={2} width="full">
          {displayedItems.length === 0 && (
            <Box textAlign={'center'} mt={10}>
              <Text>{t('There is no history available')}</Text>
            </Box>
          )}
          {displayedItems.map((item: HistoryItem) => (
            <Card key={item.id} width="full">
              <CardBody>
                <HStack justifyContent="space-between" width="full">
                  <Box sx={{ fontWeight: 'bold', color: '#33D1C9' }}>
                    <Text>{item.type === 'DP' && t('Staking')}</Text>
                    <Text>
                      {item.type === 'WD_WAIT' && t('Withdrawal Requested')}
                    </Text>
                    <Text>
                      {item.type === 'WD_OK' && t('Withdrawal Completed')}
                    </Text>
                    <Text>
                      {item.type === 'WD_NO' && t('Withdrawal Canceled')}
                    </Text>
                    <Text>
                      {item.type === 'WD_RB' &&
                        t('Withdrawal Request Returned')}
                    </Text>
                    <Text>
                      {item.type === 'REWARD_PERSONAL' && t('Personal Reward')}
                    </Text>
                    <Text>
                      {item.type === 'REWARD_TEAM' && t('Team Reward')}
                    </Text>
                    <Text>
                      {item.type === 'REWARD_VOLUME' && t('Volume Reward')}
                    </Text>
                    <Text>
                      {item.type === 'REWARD_WAVE' && t('Wave Reward')}
                    </Text>
                    <Text>
                      {item.type === 'MANUAL_STK' && t('Manual Participation')}
                    </Text>
                    <Text>
                      {item.type === 'AUTO_STK' && t('Auto Participation')}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="sm">
                      {format(new Date(item.createdAt), 'yyyy.MM.dd HH:mm')}
                    </Text>
                  </Box>
                </HStack>

                {item.tokenAmount !== '0.00000000' &&
                  item.stakingAmount !== '0.00000000' && (
                    <>
                      {/* 스테이킹 */}
                      <Box mt={2}>
                        <Text fontSize="sm" color="gray.500">
                          {t('Token Amount')}
                        </Text>
                        <Text fontSize="xl" sx={{ fontWeight: 'bold' }}>
                          {new BigNumber(item.tokenAmount).toFixed(4, 1)} WVP
                        </Text>
                      </Box>

                      {/* 토큰 */}
                      <Box mt={2}>
                        <Text fontSize="sm" color="gray.500">
                          {t('Staking Amount')}
                        </Text>
                        <Text fontSize="xl" sx={{ fontWeight: 'bold' }}>
                          {new BigNumber(item.stakingAmount).toFixed(4, 1)} USDT
                        </Text>
                      </Box>

                      <Box mt={2}>
                        <Text fontSize="sm" color="gray.500">
                          {t('Change in balance')}
                        </Text>
                      </Box>
                      <HStack spacing={2}>
                        <Text>
                          {new BigNumber(item.beforeTokenBalance).toFixed(4, 1)}{' '}
                          WVP
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          →
                        </Text>{' '}
                        <Text>
                          {new BigNumber(item.afterTokenBalance).toFixed(4, 1)}{' '}
                          WVP
                        </Text>
                      </HStack>
                      <HStack spacing={2}>
                        <Text>
                          {new BigNumber(item.beforeStakingBalance).toFixed(
                            4,
                            1
                          )}{' '}
                          USDT
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          →
                        </Text>{' '}
                        <Text>
                          {new BigNumber(item.afterStakingBalance).toFixed(
                            4,
                            1
                          )}{' '}
                          USDT
                        </Text>
                      </HStack>
                    </>
                  )}

                {item.tokenAmount === '0.00000000' &&
                  item.stakingAmount !== '0.00000000' && (
                    <>
                      {/* 스테이킹 */}
                      <Box mt={2}>
                        <Text fontSize="sm" color="gray.500">
                          {t('Staking Amount')}
                        </Text>
                        <Text fontSize="xl" sx={{ fontWeight: 'bold' }}>
                          {new BigNumber(item.stakingAmount).toFixed(4, 1)} USDT
                        </Text>
                      </Box>

                      <Box mt={2}>
                        <Text fontSize="sm" color="gray.500">
                          {t('Change in balance')}
                        </Text>
                      </Box>
                      <HStack spacing={2}>
                        <Text>
                          {new BigNumber(item.beforeStakingBalance).toFixed(
                            4,
                            1
                          )}{' '}
                          USDT
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          →
                        </Text>{' '}
                        <Text>
                          {new BigNumber(item.afterStakingBalance).toFixed(
                            4,
                            1
                          )}{' '}
                          USDT
                        </Text>
                      </HStack>
                    </>
                  )}

                {item.tokenAmount !== '0.00000000' &&
                  item.stakingAmount === '0.00000000' && (
                    <>
                      {/* 토큰 */}
                      <Box mt={2}>
                        <Text fontSize="sm" color="gray.500">
                          {t('Token Amount')}
                        </Text>
                        <Text fontSize="xl" sx={{ fontWeight: 'bold' }}>
                          {new BigNumber(item.tokenAmount).toFixed(4, 1)} WVP
                          {item.rate &&
                            item.rate !== '0.00000000' &&
                            ` ≈ ${new BigNumber(item.usdtAmount).toFixed(
                              4,
                              1
                            )} USDT`}
                        </Text>
                      </Box>

                      <Box mt={2}>
                        <Text fontSize="sm" color="gray.500">
                          {t('Change in balance')}
                        </Text>
                      </Box>
                      <HStack spacing={2}>
                        <Text>
                          {new BigNumber(item.beforeTokenBalance).toFixed(4, 1)}{' '}
                          WVP
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          →
                        </Text>{' '}
                        <Text>
                          {new BigNumber(item.afterTokenBalance).toFixed(4, 1)}{' '}
                          WVP
                        </Text>
                      </HStack>
                    </>
                  )}

                {item.txid && (
                  <Box mt={2}>
                    <Text fontSize="sm" color="gray.500">
                      {t('Transaction Hash')}
                    </Text>
                    <Link
                      href={`https://bscscan.com/tx/${item.txid}`}
                      fontSize="sm"
                      color="teal.500"
                      isExternal
                    >
                      {item.txid}
                    </Link>
                  </Box>
                )}

                {item.fee && item.fee !== '0.00000000' && (
                  <Box mt={2}>
                    <Text fontSize="sm" color="gray.500">
                      {t('Fee')}
                    </Text>
                    <Text fontSize="sm">
                      {new BigNumber(item.fee).toFixed(2, 1)} WVP
                    </Text>
                  </Box>
                )}

                {item.rate && item.rate !== '0.00000000' && (
                  <Box mt={2}>
                    <Text fontSize="sm" color="gray.500">
                      WVP/USDT
                    </Text>
                    <Text fontSize="sm">
                      {new BigNumber(item.rate).toFixed(4, 1)} WVP
                    </Text>
                  </Box>
                )}

                {item.note && (
                  <Box mt={2} sx={{ color: '#3367d9' }}>
                    <Text fontSize="sm">{item.note}</Text>
                  </Box>
                )}
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>

        {hasNextPage && (
          <Button
            width="full"
            onClick={handleLoadMore}
            isLoading={loading}
            bg="#33D1C9"
            color="#ffffff"
            size="lg"
            _hover={{ bg: '#2BA8A3' }}
          >
            {t('Load More')}
          </Button>
        )}
      </VStack>

      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('Select Filter')}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Button width="full" onClick={() => handleFilterChange('TOTAL')}>
                {t('Total')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('DP')}
              >
                {t('Staking')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('WD')}
              >
                {t('Withdrawal')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('REWARD_PERSONAL')}
              >
                {t('Personal Reward')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('REWARD_TEAM')}
              >
                {t('Team Reward')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('REWARD_VOLUME')}
              >
                {t('Volume Reward')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('REWARD_WAVE')}
              >
                {t('Wave Reward')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('MANUAL_STK')}
              >
                {t('Manual Participation')}
              </Button>
              <Button
                width="full"
                mt={2}
                onClick={() => handleFilterChange('AUTO_STK')}
              >
                {t('Auto Participation')}
              </Button>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDialogOpen(false)}>
                {t('Cancel')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </DefaultLayout>
  )
}

export default History
